import React, { Fragment } from "react";
import Gallereact from "gallereact";
import { Dialog, Transition } from "@headlessui/react";
import { saveAs } from "file-saver";

import "./style.css";

const IMAGES = [
  "/img/gallery1.jpeg",
  "/img/gallery2.jpeg",
  "/img/gallery3.jpeg",
  "/img/gallery4.jpeg",
  "/img/gallery5.jpeg",
  "/img/gallery6.jpeg",
  "/img/gallery7.jpeg",
  "/img/gallery8.jpeg",
  "/img/gallery9.jpeg",
  "/img/gallery10.jpeg",
  "/img/gallery11.jpg",
  "/img/gallery12.jpg",
  "/img/gallery13.jpg",
  "/img/gallery14.jpg",
  "/img/gallery15.jpg",
  "/img/gallery16.jpg",
  "/img/gallery17.jpg",
];

const NAVER_MAP_URL =
  "http://app.map.naver.com/launchApp/?version=11&menu=navigation&elat=37.5292711&elng=126.9248993&etitle=%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%98%81%EB%93%B1%ED%8F%AC%EA%B5%AC%20%EC%97%AC%EC%9D%98%EA%B3%B5%EC%9B%90%EB%A1%9C%20101%20C.C.M.M%EB%B9%8C%EB%94%A9";
const KAKAO_MAP_URL =
  "https://m.map.kakao.com/scheme/route?sp=&sn=&ep=37.529272050075036%2C126.92489726390208&en=%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C+%EC%98%81%EB%93%B1%ED%8F%AC%EA%B5%AC+%EC%97%AC%EC%9D%98%EA%B3%B5%EC%9B%90%EB%A1%9C+101+C.C.M.M%EB%B9%8C%EB%94%A9&by=car";
const TMAP_MAP_URL =
  "https://www.tmap.co.kr/tmap2/mobile/route.jsp?appKey=oHGRBx21y07ZDotWXUgPR4Asg6pP8SE26ArnqEU7&lat=37.5292711&lon=126.9248993&name=%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C+%EC%98%81%EB%93%B1%ED%8F%AC%EA%B5%AC+%EC%97%AC%EC%9D%98%EA%B3%B5%EC%9B%90%EB%A1%9C+101+C.C.M.M%EB%B9%8C%EB%94%A9";

const ACCOUNTS1 = [
  [`(신랑) 이정영ㆍ`, `기업 555-000074-01-011`],
  [`(부) 이용식ㆍ`, `농협 2170-2652-1080-11`],
  [`(모) 김삼례ㆍ`, `농협 2170-3252-0133-29`],
];

const ACCOUNTS2 = [
  [`(신부) 유예진ㆍ`, `카카오 3333-05-2840713`],
  [`(부) 유훈ㆍ`, `국민 448601-01-098120`],
  [`(모) 김미영ㆍ`, `국민 209702-04-171990`],
];

const CONTACTS1 = [
  [`(신랑) 이정영ㆍ`, `010-7120-6334`],
  [`(부) 이용식ㆍ`, `010-8904-6334`],
  [`(모) 김삼례ㆍ`, `010-7768-6334`],
];

const CONTACTS2 = [
  [`(신부) 유예진ㆍ`, `010-8109-0643`],
  [`(부) 유훈ㆍ`, `010-3254-0621`],
  [`(모) 김미영ㆍ`, `010-4269-4345`],
];

const shuffle = (arr) => arr.sort(() => Math.random() - 0.5);

const copyClipBoard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);

    alert("복사가 완료됐습니다.");
  } catch (error) {
    alert("복사 실패!");
  }
};

export const Concept = () => {
  const [images] = React.useState(IMAGES);
  const [isAccount1Open, setIsAccount1Open] = React.useState(false);
  const [isAccount2Open, setIsAccount2Open] = React.useState(false);
  const [isContact1Open, setIsContact1Open] = React.useState(false);
  const [isContact2Open, setIsContact2Open] = React.useState(false);

  React.useEffect(() => {
    const container = document.getElementById("map");
    const options = {
      center: new window.kakao.maps.LatLng(37.5292711, 126.9248993),
      level: 3,
      draggable: false,
    };

    const map = new window.kakao.maps.Map(container, options);

    const zoomControl = new window.kakao.maps.ZoomControl();
    map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);

    const markerPosition = new kakao.maps.LatLng(37.5292711, 126.9248993);
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
  }, []);

  return (
    <div className="concept">
      <div className="div">
        <div className="frame">
          <div className="header">
            <p className="text-wrapper">2023년 9월 16일 토요일 오후 5시</p>
            <div className="title">서울 여의도 루나미엘레 그랜드볼룸</div>
            <div className="title-2">{""}</div>
            <div className="title-3">
              이정영&nbsp;&nbsp;&amp;&nbsp;&nbsp;유예진
            </div>
          </div>
        </div>
        <div className="images">
          <img
            className="talkmedia-i"
            alt="Talkmedia i"
            src="/img/talkmedia-i-j6fb34-1.png"
          />
        </div>
        <p className="text">
          서로 다른 두 사람이 만나 함께하여 결혼을 약속합니다 <br />
          저희가 부부의 연으로 길을 가는 순간을 함께 지켜봐주세요
        </p>
        <div className="div-2" />
        <p className="p">
          <span className="span">이용식 </span>
          <span className="text-wrapper-2">·</span>
          <span className="span"> 김삼례 </span>
          <span className="text-wrapper-2">의 아들</span>
          <span className="span"> 이정영</span>
          <br />
          <span className="span"> 유훈 </span>
          <span className="text-wrapper-2">·</span>
          <span className="span"> 김미영 </span>
          <span className="text-wrapper-2">의 딸</span>
          <span className="span"> 유예진</span>
        </p>
        <div className="div-2" />
        <p className="text">
          아들과 딸이 한 가정을 이루고자 혼인의 예를 올립니다
          <br />두 자녀의 행복을 따듯한 마음으로 축복해 주시기 바랍니다
        </p>
      </div>
      <div className="div">
        <div className="frame-2">
          <div className="header-2">
            <p className="title-4">G A L L E R Y</p>
          </div>
          <div className="gallery">
            <Gallereact images={images} cover={false} swipe={true} />
          </div>
        </div>
      </div>
      <div className="div">
        <div className="header-wrapper">
          <div className="header-3">
            <div className="text-2">날짜 및 시간</div>
            <p className="title-5">2023년 9월 16일 토요일 오후 5시</p>
          </div>
        </div>
        <div className="map" id="map" />
        <div className="buttons">
          <div
            className="button"
            onClick={() => window.open(NAVER_MAP_URL, "_blank")}
          >
            <div className="text-3">네이버 지도</div>
          </div>
          <div
            className="div-wrapper"
            onClick={() => window.open(KAKAO_MAP_URL, "_blank")}
          >
            <div className="text-4">카카오 네비</div>
          </div>
          <div
            className="button-2"
            onClick={() => window.open(TMAP_MAP_URL, "_blank")}
          >
            <div className="text-3">티맵</div>
          </div>
        </div>
      </div>
      <div className="div">
        <div className="frame-2">
          <div className="header-4">
            <div className="text-2">오시는길</div>
            <p className="title-5">
              서울시 영등포구 여의공원로 101 CCMM빌딩 12층 [루나미엘레]
            </p>
          </div>
        </div>
        <div className="row">
          <div className="feature-card">
            <img className="icon" alt="Icon" src="/img/icon-2.svg" />
            <div className="title-text">
              <div className="text-5">지하철</div>
              <p className="text-6">
                <span className="span">5호선 여의나루역 1번 출구 하차</span>
                <br />
                <span className="text-wrapper-3">
                  {" "}
                  (LG쌍둥이 빌딩 여의도공원 건너편, 도보 10분)
                  <br />
                  <br />
                </span>
                <span className="span">9호선 국회의사당역 3번 출구 하차</span>
                <br />
                <span className="text-wrapper-3">
                  {" "}
                  (여의도 공원 좌측 300M 지점, 도보 6분)
                </span>
              </p>
            </div>
          </div>
          <div className="feature-card">
            <img className="icon" alt="Icon" src="/img/icon-1.svg" />
            <div className="title-text">
              <div className="text-5">버스</div>
              <p className="text-6">
                <span className="span">
                  여의도공원 환승센터 하차
                  <br />
                </span>
                <span className="text-wrapper-3">
                  (여의도 공원 건너편 CCMM빌딩)
                  <br />
                </span>
                <span className="text-wrapper-4">일반버스</span>
                <span className="text-wrapper-3">
                  {" "}
                  10, 11-1, 11-2, 83, 88, 510, 5012, 5618
                  <br />
                </span>
                <span className="text-wrapper-4">일반버스</span>
                <span className="text-wrapper-3">
                  {" "}
                  5623, 6623, 6628, 5615, 6513, 6654
                  <br />
                </span>
                <span className="text-wrapper-4">간선버스</span>
                <span className="text-wrapper-3">
                  {" "}
                  160, 30, 162, 600, 260, 662, 261, 262
                  <br />
                </span>
                <span className="text-wrapper-4">좌석버스</span>
                <span className="text-wrapper-3">
                  {" "}
                  301, 320, 871, 8600, 8601, 8601A, G6001, 5609
                  <br />
                  <br />
                </span>
                <span className="span">
                  여의도 순복음교회 하차
                  <br />
                </span>
                <span className="text-wrapper-3">
                  (여의도 공원 방향으로 200M)
                  <br />
                </span>
                <span className="text-wrapper-4">일반버스</span>
                <span className="text-wrapper-3">
                  {" "}
                  10, 1002, 5615, 5618, 5633
                  <br />
                </span>
                <span className="text-wrapper-4">일반버스</span>
                <span className="text-wrapper-3">
                  {" "}
                  5713, 7613, 5534, 6623
                  <br />
                </span>
                <span className="text-wrapper-4">간선버스</span>
                <span className="text-wrapper-3">
                  {" "}
                  461, 753, 261, 463
                  <br />
                </span>
                <span className="text-wrapper-4">좌석버스</span>
                <span className="text-wrapper-3"> 7007-1, 108</span>
              </p>
            </div>
          </div>
          <div className="feature-card">
            <img className="icon" alt="Icon" src="/img/icon.svg" />
            <div className="title-text">
              <div className="text-5">자가용</div>
              <p className="text-6">
                <span className="span">
                  강남방면
                  <br />
                </span>
                <span className="text-wrapper-3">
                  88올림픽대로 &gt; 63빌딩 &gt; 여의도 공원 직진 &gt; <br />
                  국민일보 빌딩 &gt; 지하 주차장
                  <br />
                  <br />
                </span>
                <span className="span">
                  강북방면
                  <br />
                </span>
                <span className="text-wrapper-3">
                  서강대교 이용 &gt; 순복음교회 좌회전 &gt; 태영빌딩 좌회전 &gt;{" "}
                  <br />
                  국민일보 빌딩 &gt; 지하주차장
                  <br />
                  <br />
                  마포대교 이용 &gt; 우회전 후 태영빌딩 좌회전 &gt; <br />
                  국민일보 빌딩 &gt; 지하주차장
                </span>
              </p>
            </div>
          </div>
          <div className="image-wrapper">
            <img className="image" alt="Image" src="/img/image-5.png" />
          </div>
        </div>
      </div>
      <div className="div">
        <div className="frame-3">
          <div className="header-2">
            <div className="title-6">마음 전하실 곳</div>
            <p className="text-7">
              신랑 &amp; 신부에게 축하의 마음을 전해주세요
            </p>
          </div>
        </div>
        <div className="buttons-2">
          <div className="button-31" onClick={() => setIsAccount1Open(true)}>
            <div className="text-8">신랑측 계좌번호</div>
          </div>
          <div className="button-32" onClick={() => setIsAccount2Open(true)}>
            <div className="text-8">신부측 계좌번호</div>
          </div>
        </div>
      </div>
      <div className="div">
        <div className="frame-3">
          <div className="header-2">
            <div className="title-6">연락처</div>
            <p className="text-7">신랑 신부 그리고 혼주 연락처</p>
          </div>
        </div>
        <div className="buttons-2">
          <div className="button-4" onClick={() => setIsContact1Open(true)}>
            <div className="text-9">신랑측 연락처 확인하기</div>
          </div>
          <div className="button-4" onClick={() => setIsContact2Open(true)}>
            <div className="text-9">신부측 연락처 확인하기</div>
          </div>
        </div>
      </div>
      <div className="div">
        <div className="frame-3">
          <div className="header-2">
            <div className="text-wrapper">
              서울 여의도 루나미엘레 그랜드볼룸
            </div>
            <p className="title-5">2023년 9월 16일 오후 5시</p>
          </div>
        </div>
        <div className="buttons">
          <div className="button-5" id="kakaotalk-sharing-btn" onClick={() => {
            Kakao.Share.createDefaultButton({
              container: '#kakaotalk-sharing-btn',
              objectType: 'feed',
              content: {
                title: '이정영 & 유예진 결혼합니다.',
                description: '2023년 9월 16일 토요일 오후 5시\n서울 여의도 루나미엘레 그랜드볼룸',
                imageUrl:
                  'https://jeongyoungyejin.com/img/mobile-cover3.jpeg',
                link: {
                  // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
                  mobileWebUrl: 'https://jeongyoungyejin.com',
                  webUrl: 'https://jeongyoungyejin.com',
                },
              },
              buttons: [
                {
                  title: '모바일 청첩장 보기',
                  link: {
                    mobileWebUrl: 'https://jeongyoungyejin.com',
                    webUrl: 'https://jeongyoungyejin.com',
                  },
                },
              ],
            });
          }}>
            <div className="text-10">카카오톡 공유</div>
          </div>
          <div
            className="button-6"
            onClick={() => copyClipBoard(`https://jeongyoungyejin.com`)}
          >
            <div className="text-11">URL 주소 복사</div>
          </div>
          <div
            className="button-6"
            onClick={() => {
              saveAs(
                "https://jeongyoungyejin.com/img/wedding-rsvp.png",
                "이정영유예진청첩장.png"
              );
            }}
          >
            <div className="text-12">청첩장 다운로드</div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="content">
          <img className="img" alt="Frame" src="/img/frame-5.svg" />
          <p className="text-13">© 2023 JeongYoung Lee. All Rights Reserved.</p>
        </div>
      </footer>
      <AccountDialog
        isOpen={isAccount1Open}
        closeModal={() => setIsAccount1Open(false)}
        title={`신랑측 마음 전하실 곳`}
        accounts={ACCOUNTS1}
      />
      <AccountDialog
        isOpen={isAccount2Open}
        closeModal={() => setIsAccount2Open(false)}
        title={`신부측 마음 전하실 곳`}
        accounts={ACCOUNTS2}
      />
      <ContactDialog
        isOpen={isContact1Open}
        closeModal={() => setIsContact1Open(false)}
        title={`신랑측 전화번호`}
        contacts={CONTACTS1}
      />
      <ContactDialog
        isOpen={isContact2Open}
        closeModal={() => setIsContact2Open(false)}
        title={`신부측 전화번호`}
        contacts={CONTACTS2}
      />
    </div>
  );
};

const AccountDialog = ({ isOpen, closeModal, title, accounts }) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-4xl font-medium text-gray-900"
              >
                {title}
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="defaultModal"
                  onClick={closeModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </Dialog.Title>
              <div className="mt-8">
                <p className="flex flex-row justify-between items-center text-gray-600 text-2xl">
                  <span>
                    {accounts[0][0]}
                    <strong>{accounts[0][1]}</strong>
                  </span>
                  <span>
                    {/* <button className="inline-flex justify-center rounded-md border border-transparent bg-yellow-100 px-4 py-2 text-2xlfont-medium text-yellow-900 hover:bg-yellow-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2">
                      카카오페이 송금
                    </button> */}
                    <button
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-2xl font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => copyClipBoard(accounts[0][1])}
                    >
                      계좌번호 복사
                    </button>
                  </span>
                </p>
                <p className="flex flex-row justify-between items-center mt-3 text-gray-600 text-2xl">
                  <span>
                    {accounts[1][0]}
                    <strong>{accounts[1][1]}</strong>
                  </span>
                  <span>
                    {/* <button className="inline-flex justify-center rounded-md border border-transparent bg-yellow-100 px-4 py-2 text-2xl font-medium text-yellow-900 hover:bg-yellow-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2">
                      카카오페이 송금
                    </button> */}
                    <button
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-2xl font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => copyClipBoard(accounts[1][1])}
                    >
                      계좌번호 복사
                    </button>
                  </span>
                </p>
                <p className="flex flex-row justify-between items-center mt-3 text-gray-600 text-2xl">
                  <span>
                    {accounts[2][0]}
                    <strong>{accounts[2][1]}</strong>
                  </span>
                  <span>
                    {/* <button className="inline-flex justify-center rounded-md border border-transparent bg-yellow-100 px-4 py-2 text-2xl font-medium text-yellow-900 hover:bg-yellow-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-yellow-500 focus-visible:ring-offset-2">
                      카카오페이 송금
                    </button> */}
                    <button
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-2xl font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => copyClipBoard(accounts[2][1])}
                    >
                      계좌번호 복사
                    </button>
                  </span>
                </p>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

const ContactDialog = ({ isOpen, closeModal, title, contacts }) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-4xl font-medium text-gray-900"
              >
                {title}
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="defaultModal"
                  onClick={closeModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </Dialog.Title>
              <div className="mt-8">
                <p className="flex flex-row justify-between items-center text-gray-600 text-2xl">
                  <span>
                    {contacts[0][0]}
                    <strong>{contacts[0][1]}</strong>
                  </span>
                  <span>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-2xl font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() =>
                        window.open(`tel:${contacts[0][1]}`, "_blank")
                      }
                    >
                      📞 전화하기
                    </button>
                    <button
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-2xl font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      onClick={() =>
                        window.open(`sms:${contacts[0][1]}`, "_blank")
                      }
                    >
                      ✉️ 문자하기
                    </button>
                  </span>
                </p>
                <p className="flex flex-row justify-between items-center mt-3 text-gray-600 text-2xl">
                  <span>
                    {contacts[1][0]}
                    <strong>{contacts[1][1]}</strong>
                  </span>
                  <span>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-2xl font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() =>
                        window.open(`tel:${contacts[1][1]}`, "_blank")
                      }
                    >
                      📞 전화하기
                    </button>
                    <button
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-2xl font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      onClick={() =>
                        window.open(`sms:${contacts[1][1]}`, "_blank")
                      }
                    >
                      ✉️ 문자하기
                    </button>
                  </span>
                </p>
                <p className="flex flex-row justify-between items-center mt-3 text-gray-600 text-2xl">
                  <span>
                    {contacts[2][0]}
                    <strong>{contacts[2][1]}</strong>
                  </span>
                  <span>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-2xl font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() =>
                        window.open(`tel:${contacts[2][1]}`, "_blank")
                      }
                    >
                      📞 전화하기
                    </button>
                    <button
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-2xl font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      onClick={() =>
                        window.open(`sms:${contacts[2][1]}`, "_blank")
                      }
                    >
                      ✉️ 문자하기
                    </button>
                  </span>
                </p>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);
